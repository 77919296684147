
  
  .login-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100%;
    background: url('../../assets/img/hotalImage.jpeg') no-repeat center center/cover;
  }
  
  .login-box {
    background: rgba(255, 255, 255, 0.1);
    padding: 30px;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
    text-align: center;
    backdrop-filter: blur(10px);
    width: 450px;
    height: 450px;
  }
  
  h2 {
    color: rgb(255, 255, 255);
    margin-bottom: 20px;
  }
  
  .input-group {
    margin-bottom: 20px;
    text-align: left;
  }
  
  .input-group label {
    display: block;
    color: rgb(248, 243, 243);
    font-size: 14px;
    margin-bottom: 5px;
    font-weight: 600;
  }
  
  .input-group input {
    width: 100%;
    padding: 10px;
    border:none;
    border-bottom: 2px solid #fff;
    /* border-radius: 5px; */
    background: transparent;
    outline: none;
    color: white;
  }

  .input-group input::placeholder {
    color: white !important;
    opacity: 1; /* Ensures full visibility */
    font-size: 14px;
  }
  
  /* .options {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
    font-size: 14px;
  }
  
  .options label, .options a {
    color: white;
    text-decoration: none;
  } */
  
  .login-btn {
    width: 100%;
    padding: 10px;
    border: none;
    background: white;
    color: black;
    font-size: 16px;
    cursor: pointer;
    border-radius: 5px;
    margin-top: 1rem;
  }
  
  .login-btn:hover {
    background: lightgray;
  }
  
  .login-box p {
    margin-top: 15px;
    color: white;
  }
  
  .login-box p a {
    color: white;
    text-decoration: underline;
  }
  
  .error {
    color: red;
    font-size: 12px;
    margin-top: 5px;
    display: block;
  }