/* Responsive */

@media (max-width: 479px) {
  /* dashboard */

  .llt_dash_dispatch {
    flex-direction: column;
  }
  .llt_dash_heading {
    flex-direction: column;
  }
  .llt_dash_payment_bx_1 h6,
  .llt_dash_vehicle_d1 h6,
  .llt_dash_eway_bx_1 h6 {
    font-size: 18px;
  }
  .llt_dash_branch_date {
    flex-wrap: wrap;
  }
  .llt_dash_branch_date select,
  .llt_dash_branch_date div {
    width: 100%;
  }
  /* dashboard */

  ._fleX_inds ._divForth,
  ._divForth,
  ._tierDivs {
    flex: 0 0 100%;
  }

  ._fleX_inds ._divsix,
  ._divForth,
  ._tierDivs {
    flex: 0 0 100%;
  }
  ._fleX_inds ._divFiv,
  ._divForth,
  ._tierDivs {
    flex: 0 0 100%;
  }

  ._cityInput_bx {
    flex: 0 0 100%;
  }

  .plan_search_header,
  .plan_search_header ._anvPlan_srOuter_bx {
    flex: 0 0 100% !important;
  }

  ._selectLr_table {
    height: calc(100vh - 550px) !important;
  }

  .__conect_mainBg {
    justify-content: center;
  }

  .__inr_conectBx {
    flex: 0 0 100%;
  }
  .dashboard_inner_box {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin: 3rem 1rem 0rem 1rem;
  }
  #quickUploadAccordion{
    margin-bottom: 5rem;
  }
  

  .__qwkMain_bx {
    display: flex;
    gap: 0.5rem;
    flex-wrap: wrap;
    
  }
  ._quickCards {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    border-radius: 8px;
    background-color: var(--common-white);
    word-wrap: break-word;
    border: 1px solid #e1ebf6 !important;
    padding: 18px 10px 15px;
    margin-top: 15px;
    max-width: 100%;
    height: 200px;
    width: 100%;
    align-items: center;
    cursor: pointer;
    justify-content: center;
  }

  .llt_dash_lr_r1 {
    height: 20vh;
    display: flex;
    flex-direction: column-reverse;
    gap: 0rem;
    position: unset;
  }

  .dashboard_icon {
    width: 65px;
    height: 65px;
    border-radius: 50%;
    background-color: #a2fcd2;
    display: flex;
    align-items: center;
    justify-content: center;
    position: unset;
  }
  .llt_dash_lr_stats {
    height: 100%;
  }
  .llt_dash_lr_sts_one {
    display: flex;
    gap: 8px;
    align-items: center;
    flex-direction: column;
  }

  .llt_dash_lr_sts_one p {
    text-align: center;
  }
  .delete_btn {
    margin: 0;
    display: flex;
    gap: 12px;
    align-items: center;
    flex: 0 0 100%;
    justify-content: center;
    background: beige;
    padding: 10px;
    border-radius: 10px;
  }
  .bottomFix {
    position: fixed;
    bottom: 1rem;
    border-top: 1px solid var(--light-gray);
    padding: 10px 21px;
    background: var(--common-white);
    display: flex;
    gap: 10px;
    z-index: 9;
  }

  ._midCont_body {
    border-radius: 3px;
    border: 1px solid var(--light-gray);
    background: var(--common-white);
    padding: 20px 10px;
    overflow: hidden;
    margin: 0.5rem 0rem 5rem 0rem;
}

.footer {
    text-align: left;
    padding: 0px 5px 0px 10px;
    background-color: #ffff;
    color: #333;
    font-size: 13px;
    position: fixed;
    bottom: 0rem;
    z-index: 999999;
    width: 100%;
    height: 20px;

  }
}

@media (min-width: 480px) and (max-width: 728px) {
  ._fleX_inds ._divForth,
  ._divForth,
  ._tierDivs {
    flex: 0 0 100%;
  }
  ._fleX_inds ._divFiv,
  ._divForth,
  ._tierDivs {
    flex: 0 0 100%;
  }
  ._fleX_inds ._divsix,
  ._divForth,
  ._tierDivs {
    flex: 0 0 100%;
  }

  .__conect_mainBg {
    justify-content: center;
  }

  .__inr_conectBx {
    flex: 0 0 100%;
  }

  .dashboard_inner_box {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin: 3rem 1rem 0rem 1rem;
  }
  .__qwkMain_bx {
    display: flex;
    gap: 0.5rem;
    flex-wrap: wrap;
  }
  ._quickCards {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    border-radius: 8px;
    background-color: var(--common-white);
    word-wrap: break-word;
    border: 1px solid #e1ebf6 !important;
    padding: 18px 10px 15px;
    margin-top: 15px;
    max-width: 100%;
    height: 200px;
    width: 100%;
    align-items: center;
    cursor: pointer;
    justify-content: center;
  }

  .delete_btn {
    margin: 0;
    display: flex;
    gap: 12px;
    align-items: center;
    flex: 0 0 100%;
    justify-content: center;
    background: beige;
    padding: 10px;
    border-radius: 10px;
  }


}

@media (min-width: 728px) and (max-width: 900px) {
  ._fleX_inds ._divForth,
  ._divForth,
  ._tierDivs {
    flex: 0 0 100%;
  }
  ._fleX_inds ._divFiv,
  ._divForth,
  ._tierDivs {
    flex: 0 0 100%;
  }
  ._fleX_inds ._divsix,
  ._divForth,
  ._tierDivs {
    flex: 0 0 100%;
  }

  .__conect_mainBg {
    justify-content: center;
  }

  .__inr_conectBx {
    flex: 0 0 100%;
  }

  .dashboard_inner_box {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin: 3rem 1rem 0rem 1rem;
  }
  .__qwkMain_bx {
    display: flex;
    gap: 0.5rem;
    flex-wrap: wrap;
  }
  ._quickCards {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    border-radius: 8px;
    background-color: var(--common-white);
    word-wrap: break-word;
    border: 1px solid #e1ebf6 !important;
    padding: 18px 10px 15px;
    margin-top: 15px;
    max-width: 100%;
    height: 200px;
    width: 100%;
    align-items: center;
    cursor: pointer;
    justify-content: center;
  }

  .delete_btn {
    margin: 0;
    display: flex;
    gap: 12px;
    align-items: center;
    flex: 0 0 100%;
    justify-content: center;
    background: beige;
    padding: 10px;
    border-radius: 10px;
  }

  .bottomFix {
    position: sticky;
    bottom: 7rem;
    border-top: 1px solid var(--light-gray);
    padding: 10px 21px;
    background: var(--common-white);
    display: flex;
    gap: 10px;
    z-index: 9;
  }

  /* .add_btn{
    margin-bottom: 4rem !important;
  } */

  ._midCont_body {
    border-radius: 3px;
    border: 1px solid var(--light-gray);
    background: var(--common-white);
    padding: 20px 10px;
    overflow: hidden;
    margin: 0.5rem 0rem 8rem 0rem;
}

.footer {
    text-align: left;
    padding: 0px 10px;
    background-color: #ffff;
    color: #333;
    font-size: 13px;
    position: fixed;
    bottom:-15px;
    z-index: 99;
    width: 100%;
  }

  ._fuelCard_acc {
   
    margin-bottom: 8rem;
}

#quickUploadAccordion{
  min-height: 100vh
}
._allFilts_fxs {
  display: flex;
 flex-wrap: wrap;
 margin-bottom: 5px;
 margin-top: 5px;
 justify-content: end;
}

._inpW_search{
  width: 155px;
}
}

@media (min-width: 900px) and (max-width: 991px) {
  ._fleX_inds ._divForth,
  ._divForth {
    flex: 0 0 calc(50% - 11px);
  }

  ._fleX_inds ._divFiv,
  ._divForth {
    flex: 0 0 calc(50% - 11px);
  }

  ._fleX_inds ._divsix,
  ._divForth {
    flex: 0 0 calc(50% - 11px);
  }
  ._tierDivs {
    flex: 0 0 calc(50% - 8px);
  }

  ._rtrBxs {
    flex: 0 0 100%;
    max-width: -webkit-fill-available;
  }

  ._mrkList_otrBx {
    grid-row-gap: 15px;
  }

  ._fleerBxs,
  ._materialBxs,
  ._whtBxs,
  ._peDate,
  ._payTerms_bxs {
    flex: 0 0 calc(33.33% - 8px);
    max-width: none;
    min-width: auto;
  }

  .__conect_mainBg {
    max-width: 810px;
  }

  .__inr_conectBx {
    flex: 0 0 calc(50% - 6px);
  }

  .dashboard_inner_box {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin: 3rem 1rem 0rem 1rem;
  }
  .__qwkMain_bx {
    display: flex;
    gap: 0.5rem;
    flex-wrap: wrap;
  }
  ._quickCards {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    border-radius: 8px;
    background-color: var(--common-white);
    word-wrap: break-word;
    border: 1px solid #e1ebf6 !important;
    padding: 18px 10px 15px;
    margin-top: 15px;
    max-width: 100%;
    height: 200px;
    width: 100%;
    align-items: center;
    cursor: pointer;
    justify-content: center;
  }
  footer {
    text-align: left;
    padding: 0px 10px;
    background-color: #ffff;
    color: #333;
    font-size: 13px;
    position: fixed;
    bottom:-15px;
    z-index: 99;
    width: 100%;
  }

  ._allFilts_fxs {
    display: flex;
   flex-wrap: wrap;
}
}

@media (min-width: 992px) and (max-width: 1199px) {
  ._fleX_inds ._divForth,
  ._divForth {
    flex: 0 0 calc(33.33% - 14px);
  }

  ._fleX_inds ._divFiv,
  ._divForth {
    flex: 0 0 calc(33.33% - 14px);
  }

  ._fleX_inds ._divsix,
  ._divForth {
    flex: 0 0 calc(33.33% - 14px);
  }
  ._rtrBxs {
    flex: 0 0 100%;
    max-width: -webkit-fill-available;
  }

  ._mrkList_otrBx {
    grid-row-gap: 15px;
  }

  ._fleerBxs,
  ._materialBxs,
  ._whtBxs,
  ._peDate,
  ._payTerms_bxs {
    flex: 0 0 calc(33.33% - 8px);
    max-width: none;
    min-width: auto;
  }

  .__conect_mainBg {
    max-width: 890px;
  }

  .__inr_conectBx {
    flex: 0 0 calc(50% - 6px);
  }
  
}

@media (min-width: 1200px) and (max-width: 1365px) {
  ._fleX_inds ._divForth,
  ._divForth {
    flex: 0 0 calc(33.33% - 14px);
  }

  ._fleX_inds ._divFiv,
  ._divForth {
    flex: 0 0 calc(33.33% - 14px);
  }

  ._fleX_inds ._divsix,
  ._divForth {
    flex: 0 0 calc(33.33% - 14px);
  }
  ._mrkList_otrBx {
    grid-row-gap: 15px;
    justify-content: flex-start;
  }

  ._fleerBxs,
  ._materialBxs,
  ._whtBxs,
  ._peDate,
  ._payTerms_bxs,
  ._rtrBxs {
    flex: 0 0 calc(33.33% - 10px);
    max-width: none;
    min-width: auto;
  }

  .__conect_mainBg {
    max-width: 1130px;
  }

  .__inr_conectBx {
    flex: 0 0 calc(33.33% - 8px);
  }

  .one_3rdDiv {
    flex: 0 0 35%;
  }

  .twiceDiv {
    flex: 0 0 62%;
  }
}

@media (min-width: 1366px) and (max-width: 1600px) {
  ._mrkList_otrBx {
    justify-content: flex-start;
  }

  .__conect_mainBg {
    max-width: 1200px;
  }

  .__inr_conectBx {
    flex: 0 0 calc(33.33% - 8px);
  }
}

@media (min-width: 1601px) and (max-width: 1920px) {
  .__conect_mainBg {
    max-width: 1500px;
  }

  .__inr_conectBx {
    flex: 0 0 calc(25% - 9px);
  }
}

/* Responsive */
